@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";


.QuoiTags {
	&.react-tagsinput--focused {
		border-color: #8c9196 !important;
	}
	.react-tagsinput-input {
		width: 240px;
		display: inline-block;
		font-size: 13px;
		font-weight: 400;
		border: none;
	}
	.react-tagsinput-tag {
		border-radius: 0;
		color: $dark;
		background-color: $gray-200;
		border: $dark;
	}
	.react-tagsinput-remove {
		cursor: pointer;
		font-weight: bold;
		font-size: 1.2rem;
		color: $dark;
		line-height: 1;
		vertical-align: middle;
		margin-left: 5px;
		&:hover {
			text-decoration:none;
			color: #000;
		}
	  }
}
