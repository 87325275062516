// IE FIX
html body .app-wrapper {
	height: 100vh;
  }

  // ie11 floating footer temp fix, react only
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	#root {
	  display: flex;
	  flex-direction: column;
	}
  }

.DefaultLayout {
	&.app-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}
	.app-header {
		border-bottom: 1px solid #c8ced3;
	}
	.app-main {
		flex-grow: 1;
	}
	.footer {
		font-size: 0.8em;
		border-top: 1px solid #c8ced3;
	}
}
