@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$theme-colors: (
    "cecaz": #db0029,
    "danger": #db0029,
);

@import "bootstrap";

html, body {
	font-family: 'Source Sans Pro', sans-serif;
}
h2 {
	font-size: 1.5rem;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #9a9a9a !important;
font-style: italic;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #9a9a9a !important;
font-style: italic;
}

::-ms-input-placeholder { /* Microsoft Edge */
color: #9a9a9a !important;
font-style: italic;
}

  body, .table, .dropdown-item, .navbar-light .navbar-brand, .custom-a-secondary {
	color: #666 !important;
  }

.navbar-expand-lg .navbar-nav .nav-link {
	padding-right: .5rem;
	padding-left: .5rem;
}

.form-control, .card, .input-group-text, .dropdown-menu {
-webkit-border-radius: 0 !important;
-moz-border-radius: 0 !important;
	border-radius: 0 !important;
}

.form-control:focus, .custom-select:focus {
	border-color: #ced4da !important;
	box-shadow: none !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
}

.table-active, .table-active>td, .table-active>th, .table-hover tbody tr:hover, .dropdown-item:focus, .dropdown-item:hover {
background-color: initial !important;
color: #db0129 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
background-color: #f5f5f5 !important;
}

.page-item.disabled .page-link {
color: #6c757d !important;
}

.page-item.active .page-link {
color: #fff !important;
background-color: #db0129 !important;
border-color: #db0129 !important;
}

.page-link:focus {
box-shadow: none !important;
}

.ce-button-primary:focus, .ce-button-secondary:focus {
outline: 0 !important;
}

.custom-a,
.page-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
color: #db0129 !important;
}

.custom-a:hover, .custom-a-secondary:hover {
color: #ea012c !important;
}

.mailto {
	color: inherit !important;
}
